import styled from '@emotion/styled';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  color,
} from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

import {BackgroundColorProps} from '../custom-types';

type BoxProps = SpaceProps &
  LayoutProps &
  BorderProps &
  ShadowProps &
  BackgroundColorProps;

export const Box = styled('div', {shouldForwardProp})<BoxProps>`
  ${space};
  ${layout};
  ${border};
  ${shadow};
  ${color};
`;
